import React from "react";
import { useSelector } from "react-redux";
import SuggestedTopics from "components/general/SuggestedTopics";


function PreviewTopics({ topicList, deleteTopicHandler, buttonOnClickHandler, canvasPlaceHolder }) {

	const isAdmin = useSelector(state => state.user.isAdmin)

	const deleteTopic = (topicId) => {
		deleteTopicHandler(topicId)
	}
    
	return (
		<div className="voting-view-card">
			<SuggestedTopics headline="PREVIEW"
				subtitle={isAdmin ? "" : "Wait for your turn to present topics"}
				buttonName="START VOTING"
				callBackFunction={buttonOnClickHandler}
				topicList={topicList}
				deleteTopic={deleteTopic}
				canvasPlaceholder={canvasPlaceHolder} />
		</div>
	)
}

export default PreviewTopics;