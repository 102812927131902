import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { upvoteTopic, downvoteTopic } from "actions";
import './styles.scss'



function TopicVoteButton({ topic }) {

	const [topicVotes, setTopicVotes] = useState(0)
	let dispatch = useDispatch()

	const votesUsed = useSelector(state => state.topics
		.map((item) =>
			item.voteCount
		)
		.reduce((prev, next) =>
			prev + next
		))

	let availableVotes = 5 - votesUsed

	const upvote = () => {
		dispatch(upvoteTopic(topic.topicId))
		setTopicVotes(topicVotes + 1)
	}

	const downvote = () => {
		dispatch(downvoteTopic(topic.topicId))
		setTopicVotes(topicVotes - 1)
	}

	return (
		<div className="topic-vote-button-box">
			<button className="left-button" onClick={downvote} disabled={topicVotes === 0}>-</button>
			<div className="vote-button-count">
				{topicVotes}
			</div>
			<button className="right-button" onClick={upvote} disabled={availableVotes === 0}>+</button>
		</div>
	);
}

export default TopicVoteButton;