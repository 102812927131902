const localTopicList = [
	{
		topicId: '',
		topicName: '',
		voteCount: 0,
		upvote: 0,
		downvote: 0,
	},
]

const topics = (state = localTopicList, action) => {
	if (typeof state === 'undefined') {
		return localTopicList
	}

	let voteCount = state
		.map((item) => item.voteCount)
		.reduce((prev, next) => prev + next)

	switch (action.type) {
	case 'UPVOTE_TOPIC':
		if (voteCount < 5) {
			return state.map((item) => {
				if (item.topicId !== action.topicId) {
					return item
				} else {
					return Object.assign({}, item, { voteCount: item.voteCount + 1 })
				}
			})
		}

		return state
	case 'DOWNVOTE_TOPIC':
		return state.map((item) => {
			if (item.topicId !== action.topicId) {
				return item
			} else {
				if (item.voteCount > 0) {
					return Object.assign({}, item, { voteCount: item.voteCount - 1 })
				}

				return item
			}
		})
	case 'UPDATE_LOCAL_TOPIC_LIST':
		return Object.assign([], state, action.topicList)
	default:
		return state
	}
}

export default topics
