import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { previewTopics } from 'connect'
import { deleteLocalTopic, addLocalTopic, addLocalTopicList } from 'actions'
import SuggestTopicCard from 'components/suggestTopics/SuggestTopicCard'
import SuggestedTopics from 'components/general/SuggestedTopics'
import PopUpModal from "components/general/PopUpModal";
import Timer from "components/general/Timer";
import './styles.scss'

function SuggestTopicView() {
	const meetingId = useSelector(state => state.meeting.meetingId)
	const isAdmin = useSelector(state => state.user.isAdmin)
	const localTopicList = useSelector(state => state.localTopicList.list)
	const [topicId, setTopicId] = useState(0)

	let dispatch = useDispatch()

	useEffect(() => {
		const topicList = JSON.parse(localStorage.getItem(meetingId))

		if (localTopicList.length === 0 && topicList !== null && topicList.length > 0) {
			updateLocalTopicList(topicList)
		}
	})
    
	const updateLocalTopicList = (topicList) => {
		setTopicId(topicList.length)
		dispatch(addLocalTopicList(topicList))
	}

	const previewAllTopics = () => {
		previewTopics()
	}

	const add = (newTopic) => {
		const storedTopics = JSON.parse(localStorage.getItem(meetingId))

		if (storedTopics && storedTopics.length > 0) {
			localStorage.setItem(meetingId, JSON.stringify([...storedTopics, { topicName: newTopic, topicId: topicId }]))
		} else {
			localStorage.setItem(meetingId, JSON.stringify([{ topicName: newTopic, topicId: topicId }]))
		}
        
		dispatch(addLocalTopic({ topicName: newTopic, topicId: topicId }))
		setTopicId(topicId + 1)
	}

	const deleteMyTopic = (topicId) => {
		dispatch(deleteLocalTopic(topicId))
		localStorage.setItem(meetingId, JSON.stringify(localTopicList.filter(topic => topic.topicId !== topicId)))
	}

	if (isAdmin) {
		return (
			<PopUpModal
				title="Let's brainstorm topics"
				description="Use your phone to suggest topics for the meeting"
				iconType='SUGGEST_TOPIC_TYPE'
				buttonOnClick={previewAllTopics}
				buttonText="NEXT"
				text={<Timer />} />
		)
	}
	else {
		return (
			<div className="suggested-topic-view">
				<div className="suggest-topic-container">
					<SuggestTopicCard addToMyTopics={add} meetingId={meetingId} />
				</div>
				<div className="suggested-topics-view-container">
					<SuggestedTopics
						headline="MY TOPICS"
						deleteTopic={deleteMyTopic}
						topicList={localTopicList}
						canvasPlaceholder="No suggested topics yet"
					/>
				</div>
			</div>
		)
	}
}

export default SuggestTopicView;