const initialMeetingConfig = {
	isMeetingConfig: false,
	meetingName: "Lean Coffee",
	numberOfVotesPerParticipant: 5,
	suggestTopicLengthInMinutes: 2,
	discussionLengthInMinutes: 5,
	continueDiscussionLengthInMinutes: 3
}

const meetingConfig = (state = initialMeetingConfig, action) => {
	if (typeof state === 'undefined') {
		return initialMeetingConfig
	}

	switch (action.type) {
	case 'TOGGLE_MEETING_CONFIG':
		return Object.assign({}, state, { isMeetingConfig: !state.isMeetingConfig })
	case 'UPDATE_MEETING_CONFIG':
		return Object.assign({}, state, action.meetingConfig)
	default:
		return state
	}
}

export default meetingConfig