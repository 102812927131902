import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import Countdown, { zeroPad } from 'react-countdown'
import { ReactComponent as SkipIcon } from 'assets/skip-topic-icon.svg'
import { endDiscussion, skipDiscussion, previewTopics } from 'connect'
import './styles.scss'


function Timer() {
	const meetingState = useSelector(state => state.meeting.meetingState)
	const suggestTopicsTime = useSelector(state => state.meetingConfig.suggestTopicLengthInMinutes)
	const discussionDefaultTime = useSelector(state => state.meetingConfig.discussionLengthInMinutes)
	const discussionTimeLeft = useSelector(state => state.meeting.discussionTimeInMinutes)
	const [hasSkipped, setHasSkipped] = useState(false)
       
	const getMillisFromMinutes = (seconds) => {
		return seconds * 60 * 1000
	}
    
	const [currentTimeIndex, setCurrentTimeIndex] = useState(0)
	const [timeLeft, setTimeLeft] = useState(getMillisFromMinutes(discussionDefaultTime))
	const clockRef = useRef()
    
	let timer
	let icons
 
	// Skip button will always reset timer to default time from meeting config
	const handleSkipClick = () => {
		skipDiscussion()
		setHasSkipped(true)
	}

	useEffect(() => {
		if (hasSkipped) {
			setHasSkipped(false)
			setTimeLeft(getMillisFromMinutes(discussionDefaultTime))
		} else if (discussionTimeLeft > 0) {
			setTimeLeft(getMillisFromMinutes(discussionTimeLeft))
		}
	}, [discussionTimeLeft, hasSkipped, discussionDefaultTime])

	const renderer = ({ minutes, seconds, completed }) => {
		if (completed) {
			switch (meetingState) {
			case 'SUGGEST_TOPICS':
				previewTopics()
				break
			case 'DISCUSSION':
				endDiscussion()
				break
			default:
				return null
			}

			return null
		} else {
			return <span id='countdown-timer'>{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
		}
	}

	switch (meetingState) {
	case 'SUGGEST_TOPICS':
		timer = <Countdown
			date={Date.now() + getMillisFromMinutes(suggestTopicsTime)}
			renderer={renderer}
		/>
		break
	case 'DISCUSSION':
		if (timeLeft > 0 || hasSkipped) {
			timer = <Countdown 
				key={currentTimeIndex} 
				date={Date.now() + timeLeft}
				renderer={renderer} 
				precision={3}
				ref={clockRef}
				controlled={false}
				onComplete={() => { setCurrentTimeIndex(currentTimeIndex + 1) }}/>
		}
		icons = <div className='icon-container'>
			<SkipIcon className='icon' onClick={handleSkipClick} />
		</div>
		break
	default:
		timer = null
	}

	return (
		<div className="timer">
			{timer}
			{icons}
		</div>
	)
}

export default Timer
