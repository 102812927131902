import { Client } from '@stomp/stompjs'
import { API_URL } from './config.js'
import store from './store'

let client = null

// Get meetingId from state in the store
function selectMeetingId(state) {
	return state.meeting.meetingId
}

function selectSessionId(state) {
	return state.user.sessionId
}

function isAdmin(state) {
	return state.user.isAdmin
}

function publishToWs(endpoint) {
	if (client != null && client.connected) {
		client.publish({ destination: '/app/meeting/' + selectMeetingId(store.getState()) + endpoint })
	}
}

function publishToWsWithBody(endpoint, topics) {
	if (client != null && client.connected) {
		client.publish({ body: topics, destination: '/app/meeting/' + selectMeetingId(store.getState()) + endpoint })
	}
}

// Return meetingID  
export async function createMeeting(meetingConfig) {
	const resp = await fetch(new Request('https://' + API_URL + '/new'), {
		method: 'POST',
		headers: { 'Content-Type': 'application/json' },
		body: JSON.stringify(meetingConfig)
	})
	const respJson = await resp.json()

	return respJson.meetingId
}

// Create websocket client 
export async function createClient(callBack) {

	const stompConfig = {
		brokerURL: 'wss://' + API_URL + '/lcc-websocket/websocket', 
		beforeConnect: ()=>{
			const sessionId = selectSessionId(store.getState())

			client.connectHeaders = { id: sessionId }
		},
		onConnect: () => {
			const destination = isAdmin(store.getState()) ? '/admin/' : '/participant/'
			const meetingId = selectMeetingId(store.getState())

			if(meetingId !== ''){
				client.subscribe(destination + meetingId, callBack)
				joinMeetingById(meetingId)
			}

		}
	}

	client = new Client(stompConfig)
	client.activate()
	client.reconnectDelay = 500
}

// Set subscription channel on websocket 
export async function connectToServer(websocketChannel, newMeetingId, callBack) {
	if (client !== undefined) {
		client.subscribe(websocketChannel + newMeetingId, callBack)
	}
}

// Join meeting
export async function joinMeetingById(meetingId) {
	const resp = await fetch(new Request('https://' + API_URL + '/join/' + meetingId), {
		method: 'GET',
		headers: { 'Content-Type': 'application/json' },
	})

	return resp.status
}

// Start suggesting topic and come out of lobby
export function startSuggestingTopic() {
	publishToWs('/topic/start')
}

// Add topic
export function addTopic(topicName) {
	let payload = JSON.stringify({ topic: topicName })

	publishToWsWithBody('/topic/new', payload)
}

// delete global topics
export function deleteGlobalTopic(topicId) {
	const payload = JSON.stringify({ topicId: topicId })

	publishToWsWithBody('/topic/delete', payload)
}

// Starts the voting process for prioritizing topics
export function startVoting() {
	publishToWs('/topic/vote/start')
}

// Starts the preview process for topics
export function previewTopics() {
	publishToWs('/topic/preview')
}

// Submits all votes cast by a client to their respective topics
export function submitVotes(topicList) {
	let topics = JSON.stringify({ prioritizedTopics: topicList })

	publishToWsWithBody('/topic/vote/submit', topics)
}

// Start discussion by starting the timer for all meeting participants
export function startDiscussion() {
	publishToWs('/discussion/start')
}

// End current discussion and start thumbs voting
export function endDiscussion() {
	publishToWs('/discussion/end')
}

// End the discussion and skip to next topic to discuss
export function skipDiscussion() {
	publishToWs('/discussion/skip')
}

// Update thumbs up and down to vote whether to continue discussing the current topic
export function updateVote(vote) {
	let voteType = JSON.stringify({ voteType: vote })

	publishToWsWithBody('/discussion/vote', voteType)
}

// End vote and count upvote and downvote to decide which topic to discuss next
export function continueDiscussion() {
	publishToWs('/discussion/vote/end')
}
