const initialState = {
	meetingId: "",
	toDiscuss: [],
	discussing: {
		topicId: "",
		topicName: "",
		voteCount: 0,
		upvote: 0,
		downvote: 0,
	},
	doneDiscussed: [],
	activeSubscribers: 0,
	meetingName: "",
	numberOfVotesPerParticipant: 0,
	usersThatHaveSubmittedTopics: 0,
	usersThatHaveGivenVotes: 0,
	usersThatHaveSubmittedContinueDiscussionVotes: 0,
	discussionTimeInMinutes: 0,
	meetingState: ""
}

const meeting = (state = initialState, action) => {
	if (typeof state === 'undefined') {
		return initialState
	}

	switch (action.type) {
	case 'UPDATE_MEETING':
		return Object.assign({}, state, action.meeting)

	default:
		return state
	}
}

export default meeting