import React from 'react'
import TopicListItem from 'components/general/TopicListItem'
import './styles.scss'

function AllTopicList({ toDiscussExceptTopThree }) {

	return (
		<div className='all-topic-list'>
			{toDiscussExceptTopThree.map((item, index) => (
				<TopicListItem key={index} topicName={item.topicName} voteCount={item.voteCount} />
			))}
		</div>
	)
}

export default AllTopicList
