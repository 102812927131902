import React from "react";
import { useSelector } from "react-redux";
import TopicCard from "../TopicCard";
import './styles.scss'


function SuggestedTopics({ headline, subtitle, buttonName, callBackFunction, topicList, deleteTopic, canvasPlaceholder }) {

	const meetingState = useSelector(state => state.meeting.meetingState)
	let container = null

	const handleSubmit = () => {
		callBackFunction()
	}

	if (meetingState !== 'SUGGEST_TOPICS') {
		container = <div className="button-container">
			{!callBackFunction
				? null
				: <button className="main-btn" onClick={handleSubmit}>{buttonName}</button>}
		</div>
	} 

	return (
		<div className="suggested-topics-container">
			<div className="suggested-topics-header"><b>{headline}</b></div>
			<div className="suggested-topics-sub-title"> {subtitle} </div>
			<div className="suggested-topics-content">
				{topicList.length === 0
					? <p className="suggested-topics-placeholder">{canvasPlaceholder}</p>
					:
					topicList.map((topic, index) =>
						<TopicCard topic={topic} deleteTopic={deleteTopic} key={index} waitingForOthers={false} />)
				}
			</div>
			{container}
		</div >
	)
}

export default SuggestedTopics;