import { combineReducers } from 'redux'
import user from './user'
import meeting from './meeting'
import topics from './topics'
import voteAmount from './voteAmount'
import meetingConfig from './meetingConfig'
import localTopicList from './localTopicList'


export default combineReducers({ user, meeting, topics, voteAmount, meetingConfig, localTopicList })
