import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import JoinMeetingField from 'components/startPage/JoinMeetingField'
import InfoCard from 'components/startPage/InfoCard'
import Footer from 'components/general/Footer'
import Meeting from 'components/Meeting'
import MeetingConfig from 'components/MeetingConfig'
import { connectToServer, joinMeetingById } from 'connect'
import { updateMeetingObject, toggleMeetingConfig, updateSessionId } from 'actions'
import { ReactComponent as Logo } from 'assets/logo.svg'
import { ReactComponent as Step1 } from 'assets/step1.svg'
import { ReactComponent as Step2 } from 'assets/step2.svg'
import { ReactComponent as Step3 } from 'assets/step3.svg'
import './styles.scss'

function StartPageView() {

	const isAdmin = useSelector(state => state.user.isAdmin)
	const isMeetingConfig = useSelector(state => state.meetingConfig.isMeetingConfig)
	const meetingId = useSelector(state => state.meeting.meetingId)
	const [isWrongMeetingId, setIsWrongMeetingId] = useState(false)
	const [isInputFieldEmpty, SetisInputFieldEmpty] = useState(true)
	let dispatch = useDispatch()

	const updateMeeting = (meeting) => {
		let meetingAsJson = JSON.parse(meeting.body)
		let meetingId = meeting.headers["message-id"]

		dispatch(updateSessionId(meetingId.substring(0, meetingId.lastIndexOf("-"))))
		dispatch(updateMeetingObject(meetingAsJson))
	}

	// Error handling for joining meeting. Conenct to server only after joining meeting.
	const joinMeeting = async (meetingId, inputFieldEmpty) => {
		setIsWrongMeetingId(false)
		const resp = await joinMeetingById(meetingId)

		if (resp === 404) {
			setIsWrongMeetingId(true)
			SetisInputFieldEmpty(inputFieldEmpty)
		} else {
			connectToServer('/participant/', meetingId, updateMeeting)
		}
	}

	const cardContent = {
		step1: {
			title: "Step 1",
			subtitle: "Suggest and present topics",
			paragraph: "Time to start brainstorming! Suggest topics you want to discuss within the general theme of the meeting. Later you will have the opportunity to present your topics to the rest of hte meeting.",
			picture: <Step1 className='step-icon' alt="step one" />
		},
		step2: {
			title: "Step 2",
			subtitle: "Vote for topics",
			paragraph: "There will probably not be time to discuss all the topics, so each of you will be granted a fixed amount of votes that you can distribute between the topics you like. Remember, you can vote on a topic more than once!",
			picture: <Step2 className='step-icon' alt="step two" />
		},
		step3: {
			title: "Step 3",
			subtitle: "Start discussing!",
			paragraph: "When you’re all done placing your votes, the topics will be displayed in the prioritized order according to the votes. You now have a fixed amount of time to discuss each topic before moving on to the next.",
			picture: <Step3 className='step-icon' alt="step three" />
		},
	}

	if (isMeetingConfig) {
		return <MeetingConfig />
	} else if (meetingId) {
		return <Meeting />
	} else if (!isAdmin) {
		return (
			<div className="start-page-body">
				<div className="start-page-container">
					{isWrongMeetingId && isInputFieldEmpty
						? <div className="wrong-meeting-id"><p className="wrong-meeting-id-content">Enter a meeting ID to join a meeting</p></div>
						: null}
					{isWrongMeetingId && !isInputFieldEmpty
						? <div className="wrong-meeting-id"><p className="wrong-meeting-id-content">The meeting ID does not exist. Please try again</p></div>
						: null}
					<div className="meeting-container">
						<h1 className="start-page-header">Lean Coffee</h1>
						<Logo className="start-page-logo" />
						<div className="meeting-btn-container">
							<button className="main-btn create-meeting-btn" type="button" onClick={() => dispatch(toggleMeetingConfig())}>CREATE MEETING</button>
							<p className="meeting-container-text">Or...</p>
							<JoinMeetingField callBackFunction={joinMeeting}
								meetingId={meetingId}
								placeHolder="Type meeting ID"
								buttonTitle={"JOIN"}
								iSWrongMeetingID={isWrongMeetingId} />
						</div>
					</div>
					<div className="info-container info-container-1">
						<h3>Less agenda, more structure</h3>
						<p className="info-container-body">
							The Lean Coffee is a great and easy way to facilitate group discussions.
							Our advice is to use your mobile phone as a participant, and choose one admin to
							create a meeting on a laptop and share screen with the rest of the participants. 
							The admin should join on their mobile phone as well, if they want to contribute to the meeting. 
						</p>
					</div>
					<div className="info-container info-container-2">
						<h3>Let us explain</h3>
						<div className="step-container">
							<InfoCard 
								title={cardContent.step1.title} 
								subtitle={cardContent.step1.subtitle} 
								paragraph={cardContent.step1.paragraph} 
								picture={cardContent.step1.picture}
							/>
							<InfoCard 
								title={cardContent.step2.title} 
								subtitle={cardContent.step2.subtitle} 
								paragraph={cardContent.step2.paragraph}
								picture={cardContent.step2.picture} 
							/>
							<InfoCard 
								title={cardContent.step3.title} 
								subtitle={cardContent.step3.subtitle} 
								paragraph={cardContent.step3.paragraph} 
								picture={cardContent.step3.picture}
							/>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		)
	} else return null
}

export default StartPageView;