import React, { useEffect, useState } from "react";
import { submitVotes } from "connect";
import { useDispatch, useSelector } from 'react-redux'
import { updateLocalTopicList } from 'actions'
import SuggestedTopics from "components/general/SuggestedTopics";
import WaitingView from 'components/general/WaitingView';
import './styles.scss'


function VoteOnTopics() {

	const globalTopicList = useSelector(state => state.meeting.toDiscuss)
	const localTopicList = useSelector(state => state.topics)
	const [doneVoting, setDoneVoting] = useState(false)
	const meetingId = useSelector(state => state.meeting.meetingId)

	const votes = useSelector(state => state.topics
		.map((item) =>
			item.voteCount
		)
		.reduce((prev, next) =>
			prev + next
		))

	let availableVotes = 5 - votes
	let dispatch = useDispatch()

	useEffect(() => {
		if (localTopicList[0].topicId === "" && globalTopicList.length !== 0) {
			let topics = globalTopicList.map(topic => topic.voteCount === 0 ? topic : { ...topic, voteCount: 0 })

			dispatch(updateLocalTopicList(topics))
		}
	})

	const finishVoting = () => {
		submitVotes(localTopicList, meetingId)
		setDoneVoting(true)
	}

	if (doneVoting) {
		return <WaitingView title={"Waiting for others to finish voting..."} />
	}

	return (
		<div className="voting-view-card">
			<SuggestedTopics headline="VOTE!"
				buttonName="I'M FINISHED VOTING"
				callBackFunction={finishVoting}
				topicList={globalTopicList}
				subtitle={"You have " + availableVotes + " votes left"}
				canvasPlaceholder="No topics were suggested this meeting" />
		</div>
	)
}

export default VoteOnTopics;