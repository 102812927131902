import React from 'react'
import { useSelector } from 'react-redux'
import { continueDiscussion } from 'connect'
import CurrentTopic from 'components/discussing/CurrentTopic'
import DiscussionTopicList from 'components/discussing/DiscussionTopicList'
import PopUpModal from 'components/general/PopUpModal'
import WaitingView from 'components/general/WaitingView'
import './styles.scss'


function DiscussionView() {
	const meetingState = useSelector(state => state.meeting.meetingState)
	const isAdmin = useSelector(state => state.user.isAdmin)
	let popUpModalMaster = null
	let popUpModalUser = null

	const discussionFinishVote = () => {
		continueDiscussion()
	}

	if (meetingState === "VOTE_ON_CONTINUE_DISCUSSION") {
		const title = "Time's up!"
		const description = "Would you like to continue discussing this topic?"
		const masterIconType = "MASTER_DISCUSSION_TYPE"
		const userIconType = "USER_DISCUSSION_TYPE"
		const buttonText = "CONTINUE DISCUSSING"

		popUpModalMaster = <PopUpModal
			title={title}
			description={description}
			iconType={masterIconType}
			buttonOnClick={discussionFinishVote}
			buttonText={buttonText}
		/>

		popUpModalUser = <PopUpModal
			title={title}
			description={description}
			iconType={userIconType}
			buttonOnClick={discussionFinishVote}
		/>
	}

	if (isAdmin) {
		return (
			<div className='discussion-view'>
				<div className='list-container'>
					<div className="now-discussing-box">
						<CurrentTopic />
					</div>
					<div className="to-be-discussed-box">
						<DiscussionTopicList category='TO BE DISCUSSED' />
					</div>
					<div className="finished-discussing-box">
						<DiscussionTopicList category='DONE' />
					</div>

				</div>
				{popUpModalMaster}
			</div>
		)
	} else {
		return (
			<div>
				<WaitingView title={"Time to discuss!"} />
				{popUpModalUser}
			</div>
		)
	}
}

export default DiscussionView
