import React from 'react'
import './styles.scss'

function InfoCard({ title, subtitle, paragraph, picture }){

	return(
		<div className="step-body">
			{picture}
			<h3 className="step-header">{title}</h3>
			<h4 className="step-header-2">{subtitle}</h4>
			<p className="step-info">{paragraph}</p>
		</div>

	);
}

export default InfoCard;