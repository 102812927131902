import React from 'react'
import { useSelector } from 'react-redux'
import TopicCard from 'components/general/TopicCard';
import TopThreeFigure from 'assets/top-three.svg';
import './styles.scss'


function TopicRanking({ topThree }) {

	const activeSubscribers = useSelector(state => state.meeting.activeSubscribers)
	const usersThatHaveGivenVotes = useSelector(state => state.meeting.usersThatHaveGivenVotes)

	return (
		<div className='topic-ranking'>
			<h1>CANVAS</h1>
			<p className="has-finished-voting"><b>{usersThatHaveGivenVotes}/{activeSubscribers}</b> have finished voting</p>
			<div className="top-three-box">
				{topThree.length > 0 &&
					<div className="first-place-box">
						<TopicCard topic={topThree[0]} />
					</div>
				}
				<div className="second-place-box">
					{topThree.length > 1 && <TopicCard topic={topThree[1]} />}
				</div>
				<div className="third-place-box">
					{topThree.length > 2 && <TopicCard topic={topThree[2]} />}
				</div>
			</div>
			<img className="top-three-figure" src={TopThreeFigure} alt="" />
		</div>
	)
}

export default TopicRanking
