const initialState = { 
	isAdmin: false,
	sessionId: null
}
  
const user = (state = initialState, action) => {
	if (typeof state === 'undefined') {
		return initialState
	}

	switch (action.type) {
	case 'SET_ADMIN':
		return { ...state, isAdmin: true }
	case 'UPDATE_SESSIONID':
		return { ...state, sessionId: action.sessionId }
	default:
		return state
	}
}

export default user