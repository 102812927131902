import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { createClient } from 'connect'
import StartPageView from 'components/startPage/StartPageView'
import { updateMeetingObject, updateSessionId } from 'actions'
import './styles.scss'
import './styles.scss'

function App() {


	let dispatch = useDispatch()

	const updateMeeting = (meeting) => {
		
		let meetingAsJson = JSON.parse(meeting.body)
		let meetingId = meeting.headers["message-id"]
		
		dispatch(updateSessionId(meetingId.substring(0, meetingId.lastIndexOf("-"))))
		dispatch(updateMeetingObject(meetingAsJson))
	}

	useEffect(() => {
		createClient(updateMeeting)
	});

	return <StartPageView />
}

export default App
