import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { updateVote } from 'connect'
import { ReactComponent as ThumbsUpIcon } from 'assets/thumbs-up-icon.svg'
import { ReactComponent as ThumbsDownIcon } from 'assets/thumbs-down-icon.svg'
import { ReactComponent as CoffeeIcon } from 'assets/coffee-icon.svg'
import { ReactComponent as TimerIcon } from 'assets/timer-icon.svg'
import './styles.scss'


function PopUpModal({ title, description, iconType, buttonOnClick, buttonText, text }) {
	const meetingId = useSelector(state => state.meeting.meetingId)
	const activeSubscribers = useSelector(state => state.meeting.activeSubscribers)
	const usersThatHaveSubmittedContinueDiscussionVotes = useSelector(state => state.meeting.usersThatHaveSubmittedContinueDiscussionVotes)
	const topic = useSelector(state => state.meeting.discussing)
	const [hasVoted, setHasVoted] = useState(false)
	let titleText
	let icon
	let fraction
	let castedVotes
	let button = buttonOnClick && buttonText && <button className='main-btn' onClick={buttonOnClick}>{buttonText}</button>

	const discussionUpVote = () => {
		updateVote("upVote", meetingId)
		setHasVoted(true)
	}

	const discussionDownVote = () => {
		updateVote("downVote", meetingId)
		setHasVoted(true)
	}

	if (title === 'Meeting has ended') {
		titleText = <h2>{title}</h2>
	} else if (title) {
		titleText = <h2 className='modal-title'>{title}</h2>
	}

	switch (iconType) {
	case 'FINISHED_SUGGESTING_TOPIC_TYPE':
		icon = <div className='icon-container'>
			<CoffeeIcon />
		</div>
		break
	case 'USER_DISCUSSION_TYPE':
		if (!hasVoted){
			icon = <div className='icon-container two'>
				<ThumbsUpIcon className='icon-container two icon' onClick={discussionUpVote} />
				<ThumbsDownIcon className='icon-container two icon' onClick={discussionDownVote} />
			</div>	
		} else {
			icon = <div className="icon-container filler"></div>
			titleText = <h2 className='modal-title'>You have voted!</h2>
			description = "Waiting for others to finish their vote..."

		}
		button = null
		break
	case 'MASTER_DISCUSSION_TYPE':
		if (topic) {
			fraction = <p><b>{usersThatHaveSubmittedContinueDiscussionVotes}/{activeSubscribers}</b> have finished voting</p>
			castedVotes = <p>Upvotes: {topic.upvote} Downvotes: {topic.downvote}</p>
    
			text = <div>{fraction}{castedVotes}</div>
		}
		break
	case 'SUGGEST_TOPIC_TYPE':
		icon = <TimerIcon />
		break;
	default:
		icon = null
	}

	return (
		<div className='pop-up-modal'>
			{titleText}
			{description && <h4>{description}</h4>}
			{icon}
			{text}
			{button}
		</div >
	)
}

export default PopUpModal
