import React, { useState } from 'react'
import { connectToServer, createMeeting, joinMeetingById } from 'connect'
import { useDispatch, useSelector } from 'react-redux'
import { updateMeetingObject, toggleMeetingConfig, setAdmin, updateMeetingConfig, updateSessionId } from 'actions'
import './styles.scss'

function MeetingConfig() {
	const [discussionLengthInMinutes, setDiscussionLengthInMinutes] = useState("5")
	const [continueDiscussionLengthInMinutes, setContinueDiscussionLengthInMinutes] = useState("3")
	const [suggestTopicLengthInMinutes, setSuggestTopicLengthInMinutes] = useState("1")
	const [meetingName, setMeetingName] = useState("Lean Coffee")

	let dispatch = useDispatch()
	const isMeetingConfig = useSelector(state => state.meetingConfig)

	const updateMeeting = (meeting) => {
		let meetingAsJson = JSON.parse(meeting.body)
		let meetingId = meeting.headers["message-id"]
		
		dispatch(updateSessionId(meetingId.substring(0, meetingId.lastIndexOf("-"))))

		dispatch(updateMeetingObject(meetingAsJson))
	}

	const newMeeting = async () => {
		if (isNaN(parseInt(discussionLengthInMinutes)) || isNaN(parseInt(continueDiscussionLengthInMinutes)) || isNaN(parseInt(suggestTopicLengthInMinutes))) {
			alert("Please type in a number!")
		} else {
			const meetingConfig = {
				meetingName: meetingName,
				numberOfVotesPerParticipant: 5,
				suggestTopicLengthInMinutes: parseInt(suggestTopicLengthInMinutes),
				discussionLengthInMinutes: parseInt(discussionLengthInMinutes),
				continueDiscussionLengthInMinutes: parseInt(continueDiscussionLengthInMinutes)
			}
			let meetingId = await createMeeting(meetingConfig)

			connectToServer("/admin/", meetingId, updateMeeting)
			joinMeetingById(meetingId)

			dispatch(toggleMeetingConfig())
			dispatch(updateMeetingConfig(meetingConfig))
			dispatch(setAdmin())
		}
	}

	const handleMeetingName = e => {
		setMeetingName(e.target.value)
	}

	const handleDiscussionLengthInMinutes = e => {
		setDiscussionLengthInMinutes(e.target.value)
	}

	const handleContinueDiscussionLengthInMinutes = e => {
		setContinueDiscussionLengthInMinutes(e.target.value)
	}

	const handleSuggestTopicLengthInMinutes = e => {
		setSuggestTopicLengthInMinutes(e.target.value)
	}


	if (isMeetingConfig)
		return (
			<div className='meeting-config'>
				<div className='config-container'>
					<div className='config-component-container top'>
						<div className='config-info-container top'>
							<p>Start by giving your meeting <br /> a name ...</p>
						</div>
						<div className='config-form-container top'>
							<div className='form-container-text top'>
								<input
									className="meeting-name-input-field"
									type="text"
									placeholder="Type meeting name"
									onChange={handleMeetingName}
									name="inputField"
								/>
							</div>
						</div>
					</div>


					<div className='config-component-container second'>
						<div className='config-form-container second'>
							<label className="number-label">
								<input
									type="tel"
									min="1"
									className="number-input-field"
									placeholder="..."
									onClick={handleSuggestTopicLengthInMinutes}
									onChange={handleSuggestTopicLengthInMinutes} />
									minutes
							</label>
						</div>
						<div className='config-info-container second'>
							<p>...is how long we should use on brainstorming topics in the beginning</p>
						</div>
					</div>


					<div className='config-component-container third'>
						<div className='config-info-container third'>
							<p>When discussing a new topic, we want the discussion to last for ...</p>
						</div>
						<div className='config-form-container third'>
							<label>
								<input
									type="tel"
									min="1"
									className="number-input-field"
									placeholder="..."
									onClick={handleDiscussionLengthInMinutes}
									onChange={handleDiscussionLengthInMinutes} />
									minutes
							</label>
						</div>
					</div>


					<div className='config-component-container bottom'>
						<div className='config-form-container bottom'>
							<div className='form-container-text bottom'>
								<p>...and</p>
								<label>
									<input
										type="tel"
										min="1"
										className="number-input-field"
										placeholder="..."
										onClick={handleContinueDiscussionLengthInMinutes}
										onChange={handleContinueDiscussionLengthInMinutes} />
									minutes
								</label>
							</div>
						</div>
						<div className='config-info-container bottom'>
							<p>... if we want to continue on the same topic</p>
						</div>
					</div>


				</div>
				<button className='main-btn' onClick={newMeeting}>CONTINUE</button>
			</div>
		);

	return null;
}


export default MeetingConfig;