import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import TopicListItem from 'components/general/TopicListItem'
import './styles.scss'


function DiscussionTopicList({ category }) {
	const [topicList, setTopicList] = useState([])
	const toDiscuss = useSelector(state => state.meeting.toDiscuss)
	const doneDiscussed = useSelector(state => state.meeting.doneDiscussed)

	useEffect(() => {
		if (category === 'TO BE DISCUSSED' && toDiscuss !== topicList) {
			setTopicList(toDiscuss)
		} else if (category === 'DONE' && doneDiscussed !== topicList) {
			setTopicList(doneDiscussed)
		}
	}, [category, topicList, toDiscuss, doneDiscussed])

	if (topicList) {
		return (
			<div className="discussion-topic-list">
				<div className='category-container'>
					<h5>{category}</h5>
				</div>
				<div className='topic-list-container'>
					<div className='item-container'>
						{topicList.map((item, index) => (
							<TopicListItem key={index} topicName={item.topicName} voteCount={item.voteCount} category={category}/>
						))}
					</div>
				</div>
			</div>
		)
	} else return null
}

export default DiscussionTopicList