import React from 'react'
import './styles.scss'

function TopicListItem({ topicName, voteCount, category }) {
	if(category === 'TO BE DISCUSSED'){
		return (
			<div className='topic-list-item'>
				<div className='vote-count'>
					<p>{voteCount} VOTES</p>
				</div>
				<div className='topic-name'>
					<p>{topicName}</p>
				</div>
			</div>
		)
	} else {
		return (
			<div className='topic-list-item'>
				<div className='topic-name right'>
					<p>{topicName}</p>
				</div>
				<div className='vote-count right'>
					<p>{voteCount} VOTES</p>
				</div>
			</div>
		)
	}
}

export default TopicListItem



