import React from 'react'
import { useSelector } from 'react-redux'
import './styles.scss'


function CurrentTopicName() {
	const topic = useSelector(state => state.meeting.discussing)
	const doneDiscussed = useSelector(state => state.meeting.doneDiscussed)

	if (topic) {
		return (
			<div className='current-topic-name'>
				<p id='topic'>TOPIC #{doneDiscussed.length + 1}</p>
				<p id='topic-name'>{topic.topicName}</p>
			</div>
		)
	} else return null
}

export default CurrentTopicName
