import React from 'react'
import { startSuggestingTopic } from 'connect'
import { useSelector } from 'react-redux'
import './styles.scss'
import WaitingView from 'components/general/WaitingView'

function LobbyView() {
	const meetingId = useSelector(state => state.meeting.meetingId)
	const activeSubscribers = useSelector(state => state.meeting.activeSubscribers)
	const isAdmin = useSelector(state => state.user.isAdmin)

	if (isAdmin) {
		return(
			<div className='lobby-view'>
				<h2>Join meeting with ID: {meetingId}</h2>
				<h3>Number of participants: {activeSubscribers}</h3>
				<button className='main-btn' onClick={startSuggestingTopic}>START</button>
			</div>
		)
	} else {
		return (
			<WaitingView title={"Waiting for the meeting to start..."}/>
		)
	}
}

export default LobbyView
