import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import SuggestedTopicView from 'components/suggestTopics/SuggestTopicView'
import VotingView from 'components/voting/VotingView'
import DiscussionView from 'components/discussing/DiscussionView'
import DoneVotingView from 'components/doneVoting/DoneVotingView'
import PreviewTopicsView from 'components/previewTopics/PreviewTopicsView'
import PopUpModal from 'components/general/PopUpModal'
import LobbyView from 'components/LobbyView'
import Header from 'components/general/Header'
import "./styles.scss"


function Meeting() {
	const meetingState = useSelector(state => state.meeting.meetingState)
	const isAdmin = useSelector(state => state.user.isAdmin)
	let view
    
	useEffect(() => {
		if (isAdmin) {
			window.onbeforeunload = confirmExit;
		}
	}, [isAdmin])
    
	const confirmExit = () => {
		return "show warning";
	}

	if (meetingState === "DISCUSSION" || meetingState === "VOTE_ON_CONTINUE_DISCUSSION") {
		view = <DiscussionView />
	} else if (meetingState === "PREVIEW") {
		view = <PreviewTopicsView />
	} else if (meetingState === "MEETING_DONE") {
		view = <PopUpModal title="Meeting has ended" />
	} else if (meetingState === "SUGGEST_TOPICS") {
		view = <SuggestedTopicView />
	} else if (meetingState === "VOTE_ON_TOPICS") {
		view = <VotingView />
	} else if (meetingState === "GET_READY_TO_DISCUSS") {
		view = <DoneVotingView />
	} else if (meetingState === 'LOBBY') {
		view = <LobbyView />
	}

	return (
		<div className="meeting">
			<Header />
			<div className="meeting-body">{view}</div>
		</div >
	)
}

export default Meeting
