import React from 'react'
import './styles.scss'


function Footer(){
	return(
		<div className="footer-container">
			<div className="footer-content-container">
				<h1 className="footer-text">netlight</h1>
			</div>
		</div>
	);
}

export default Footer
