import React from 'react'
import { ReactComponent as TimerIcon } from 'assets/timer-icon.svg'
import Timer from 'components/general/Timer'
import CurrentTopicName from '../CurrentTopicName'
import './styles.scss'


function CurrentTopic() {

	return (
		<div className="current-topic">
			<div className='category-container'>
				<h5>DISCUSSING</h5>
			</div>
			<div className='current-topic-list-container'>
				<CurrentTopicName />
				<TimerIcon />
				<Timer />
			</div>
		</div>
	)
	
}

export default CurrentTopic
