import React from "react";
import './styles.scss'
import { addTopic } from 'connect'
import { useSelector } from "react-redux";
import TopicVoteButton from "components/voting/TopicVoteButton";
import { ReactComponent as DeleteTopicIcon } from 'assets/delete-topic-icon.svg'


function TopicCard({ topic, deleteTopic }) {

	const meetingState = useSelector(state => state.meeting.meetingState)
	const isAdmin = useSelector(state => state.user.isAdmin)

	const handleDeleteTopic = () => {
		deleteTopic(topic.topicId)
	};

	const handleAddTopic = () => {
		addTopic(topic.topicName)
		deleteTopic(topic.topicId)

	};

	const setFontSize = () => {
		if (topic.topicName.length > 80) {
			return "0.7rem"
		} else if (topic.topicName.length > 60) {
			return "0.8rem"
		} else if (topic.topicName.length > 40) {
			return "0.9rem"
		} else {
			return "1rem"
		}
	}

	const topicStyle = { fontSize: setFontSize() };

	return (
		<div className="topic-card" style={ topicStyle }>
			<div className="delete-btn-container">
				{
					(meetingState === "SUGGEST_TOPICS" || meetingState === "PREVIEW")
						? <button onClick={handleDeleteTopic} className="delete-topic-btn"><DeleteTopicIcon className="delete-topic-icon" alt="delete button" /></button>
						: null
				}
			</div>
			<div className="topic-card-name">{topic.topicName}</div>
			{
				(meetingState === "PREVIEW") && !isAdmin
					? <button className="secondary-btn white small" onClick={handleAddTopic}>ADD TO CANVAS</button>
					: <div />
			}
			{
				meetingState === "VOTE_ON_TOPICS" && !isAdmin
					? <TopicVoteButton topic={topic} />
					: <div />
			}
			{
				meetingState === "VOTE_ON_TOPICS" && isAdmin
					? <div className="vote-count-card"><p>{topic.voteCount}</p></div>
					: <div />
			}
		</div >
	)
}

export default TopicCard;