export const setAdmin = () => ({ type: 'SET_ADMIN' })

export const updateMeetingConfig = meetingConfig => ({
	type: 'UPDATE_MEETING_CONFIG',
	meetingConfig
})

export const updateMeetingObject = meeting => ({
	type: 'UPDATE_MEETING',
	meeting
})

export const updateSessionId = sessionId => ({
	type: 'UPDATE_SESSIONID',
	sessionId
})

export const updateLocalTopicList = topicList => ({
	type: 'UPDATE_LOCAL_TOPIC_LIST',
	topicList
})

export const upvoteTopic = topicId => ({
	type: 'UPVOTE_TOPIC',
	topicId
})

export const downvoteTopic = topicId => ({
	type: 'DOWNVOTE_TOPIC',
	topicId
})

export const reduceVoteCounter = votes => ({
	type: 'REDUCE_VOTE_COUNTER',
	votes
})

export const increaseVoteCounter = votes => ({
	type: 'INCREASE_VOTE_COUNTER',
	votes
})

export const toggleMeetingConfig = () => ({ type: 'TOGGLE_MEETING_CONFIG' })

export const addLocalTopic = newTopic => ({
	type: 'ADD_TOPIC',
	newTopic: newTopic
})

export const addLocalTopicList = newTopicList => ({
	type: 'ADD_TOPIC_LIST',
	newTopicList: newTopicList
})

export const deleteLocalTopic = topicId => ({
	type: 'DELETE_TOPIC',
	topicId: topicId
})