import React, { useEffect } from "react";
import { deleteGlobalTopic, startVoting } from 'connect'
import PreviewTopics from "components/previewTopics/PreviewTopics"
import { deleteLocalTopic, addLocalTopicList } from 'actions'
import { useDispatch, useSelector } from 'react-redux'


function PreviewTopicsView() {

	const meetingId = useSelector(state => state.meeting.meetingId)
	const isAdmin = useSelector(state => state.user.isAdmin)
	const globalTopicList = useSelector(state => state.meeting.toDiscuss)
	const localTopicList = useSelector(state => state.localTopicList.list)

	let dispatch = useDispatch()

	useEffect(() => {
		const topicList = JSON.parse(localStorage.getItem(meetingId))

		if(localTopicList.length === 0 && topicList !== null && topicList.length > 0){
			dispatch(addLocalTopicList(topicList))
		}
	})

	if (isAdmin) {
		const buttonOnClickHandler = () => {
			startVoting()
		}
        
		const deleteTopic = (topicId) => {
			deleteGlobalTopic(topicId)
		}

		return (
			<PreviewTopics topicList={globalTopicList} deleteTopicHandler={deleteTopic} buttonOnClickHandler={buttonOnClickHandler}
				canvasPlaceHolder="Each participant should present their topics in turn" />
		)
	} else {
		const deleteTopic = (topicId) => {
			dispatch(deleteLocalTopic(topicId))
			localStorage.setItem(meetingId, JSON.stringify(localTopicList.filter(topic => topic.topicId !== topicId)))
		}

		return (
			<PreviewTopics topicList={localTopicList} deleteTopicHandler={deleteTopic}
				canvasPlaceHolder="All topics submitted to canvas. Wait for admin to continue" />
		)
	}
}

export default PreviewTopicsView;