const maxVotes = 5
  
let voteCounter = 0

const voteAmount = (state = voteCounter, action) => {
	if (typeof state === 'undefined') {
		return voteCounter
	}

	switch (action.type) {
	case 'REDUCE_VOTE_COUNTER':
		if(state <= maxVotes && state > 0){
			return state -=1
		}

		return state
	case 'INCREASE_VOTE_COUNTER':
		if(0 <= state && state < maxVotes){
			return state += 1
		}

		return state
	default:
		return state
	}
}

export default voteAmount