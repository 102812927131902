import React from 'react'
import { useSelector } from 'react-redux'
import { ReactComponent as Logo } from 'assets/logo.svg'
import "./styles.scss"

function Header() {
	const meetingId = useSelector(state => state.meeting.meetingId)
	const activeSubscribers = useSelector(state => state.meeting.activeSubscribers)
	const meetingName = useSelector(state => state.meeting.meetingName)
	const isAdmin = useSelector(state => state.user.isAdmin)
	let numberOfParticipants = null
	let meetingIdText = null

	if (isAdmin) {
		numberOfParticipants = <div className="meeting-participants">Number of Participants: {activeSubscribers}</div>
		meetingIdText = <div className="meeting-id">Meeting ID: <u>{meetingId}</u></div>
	}

	return (
		<div className="meeting-header">

			<div className="logo-container">
				<div className="meeting-name">{meetingName}</div>
				<Logo className="logo" />
			</div>

			<div className="id-and-participants-container">
				{meetingIdText}
				{numberOfParticipants}
			</div>

		</div>
	)
}

export default Header

