import React, { useState } from 'react'
import './styles.scss'


function SuggestTopicCard({ addToMyTopics }) {

	const [topic, updateTopic] = useState("");
	const [letterCount, updateLetterCount] = useState(0);

	const handleInputChange = (e) => {
		e.preventDefault()
		updateTopic(e.target.value)
		updateLetterCount(e.target.value.length)
	};

	const handleButtonSubmit = (e) => {
		if (topic !== "") {
			e.preventDefault()
			addToMyTopics(topic)
			updateTopic("")
			updateLetterCount(0)
		}
	};

	const handleFormSubmit = (e) => {
		e.preventDefault()
	}

	return (
		<form className="suggest-topic-card-container" onSubmit={handleFormSubmit}>
			<p className="letter-count">{letterCount}/100</p>
			<input 
				className="suggest-topic-card-input" 
				onChange={handleInputChange} 
				type="text" 
				placeholder="Suggest topics here..."
				maxLength="100" 
				value={topic}
				autoComplete="off" 
				autoCorrect="off" 
				autoCapitalize="off" 
				spellCheck="false" 
			/>
			<button className="secondary-btn white medium" onClick={handleButtonSubmit}>SUBMIT TO CANVAS</button>
		</form >
	)
}

export default SuggestTopicCard
