import React from "react";
import { useSelector } from 'react-redux'
import { startDiscussion } from "connect";
import Countdown from 'react-countdown'
import PopUpModal from "components/general/PopUpModal";
import TopicRanking from "components/doneVoting/TopicRanking";
import AllTopicList from "components/doneVoting/AllTopicList";
import './styles.scss'

function DoneVotingView() {
	const meetingState = useSelector(state => state.meeting.meetingState)
	let modal = null

	const toDiscuss = useSelector(state => state.meeting.toDiscuss
		.sort((a, b) => a.voteCount > b.voteCount ? -1 : 1))

	const toDiscussExceptTopThree = toDiscuss.slice(3, toDiscuss.length)
	const topThree = toDiscuss.slice(0, 3)


	const renderer = ({ seconds, completed }) => {
		if (completed) {
			return null
		} else {
			return <h1>{seconds}</h1>;
		}
	};

	const title = "All the votes are in!"
	const description = "Get ready to start discussing"
	const countdown = <Countdown date={Date.now() + 5000} renderer={renderer} />

	modal = <PopUpModal title={title} description={description} text={countdown} />

	if (meetingState === 'GET_READY_TO_DISCUSS') {
		return (
			<div>
				{ modal}
			</div>
		)
	}
	else {
		return (
			<div className='done-voting-view'>
				<div className='done-voting-container'>
					<TopicRanking topThree={topThree} />
					<AllTopicList toDiscussExceptTopThree={toDiscussExceptTopThree} />
				</div>
				<button className='main-btn' onClick={startDiscussion}>START DISCUSSION</button>
			</div>
		)
	}

}

export default DoneVotingView;