import React, { useState } from "react";
import './style.scss'

function JoinMeetingField({ meetingId, placeHolder, buttonTitle, callBackFunction, iSWrongMeetingID }) {
	const [formData, updateFormData] = useState("");
	const [hasInputChangedAfterSubmit, setHasInputChangedAfterSubmit] = useState(false)

	const handleChange = (e) => {
		setHasInputChangedAfterSubmit(false)
		updateFormData({
			...formData,
			[e.target.name]: e.target.value.trim(),
		});
	};

	const handleSubmit = (e) => {
		setHasInputChangedAfterSubmit(true)
		e.preventDefault();
		if (formData.inputField === "") {
			callBackFunction(formData.inputField, true)
		}
		else {
			callBackFunction(formData.inputField, false)
		}

	};

	return (
		<form>
			<div className="join-meeting-field">
				<div className={"join-meeting-input-container" + ((iSWrongMeetingID && hasInputChangedAfterSubmit) ? ' error' : '')}>
					<input
						className="join-meeting-input-field"
						type="tel"
						placeholder={placeHolder}
						onChange={handleChange}
						name="inputField"
						maxLength="5"
						autoComplete="off" 
						autoCorrect="off" 
						autoCapitalize="off" 
						spellCheck="false"
					/>
				</div>
				<button className="join-meeting-btn" onClick={handleSubmit}>{buttonTitle}</button>
			</div>
		</form>
	);
}

export default JoinMeetingField;