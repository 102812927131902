import React, { useEffect } from "react";
import { useSelector } from 'react-redux'
import VoteOnTopics from "components/voting/VoteOnTopics"
import DoneVotingView from 'components/doneVoting/DoneVotingView'

function VotingView() {

	const isAdmin = useSelector(state => state.user.isAdmin)

	useEffect(() => {
		localStorage.clear()
	})

	if (isAdmin) {
		return (
			<DoneVotingView />
		)
	} else {
		return (
			<VoteOnTopics />
		)
	}


}

export default VotingView;