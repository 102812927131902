import React from 'react'
import { ReactComponent as CoffeeIcon } from 'assets/coffee-icon.svg'
import './styles.scss'

function WaitingView({ title }) {

	return (
		<div className="waiting-view-container">
			<CoffeeIcon />
			<h1 className="waiting-view-text">{title}</h1>
		</div>
	)
}

export default WaitingView
