
const initialState = { list: [] }

const localTopicList = (state = initialState, action) => {

	if (typeof state === 'undefined') {
		return initialState
	}

	switch (action.type) {
	case 'ADD_TOPIC':
		return {
			...state,
			list: [...state.list, action.newTopic]
		}
	case 'ADD_TOPIC_LIST':
		return {
			...state,
			list: [...state.list, ...action.newTopicList]
		}
	case 'DELETE_TOPIC':
		return {
			...state,
			list: state.list.filter(topic => topic.topicId !== action.topicId)
		}
	default:
		return state
	}

}

export default localTopicList;